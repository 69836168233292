<template>
  <div class="datatable">
    <div
      v-if="enableExport"
      class="d-flex align-items-center justify-content-start mb-1"
    >
      <CIcon name="cil-cloud-download" />
      <CDropdown
        toggler-text="Exportar tabela "
        placement="bottom-end"
        add-toggler-classes="p-0"
      >
        <CDropdownItem @click="exportTable('xls')"> XLS </CDropdownItem>
      </CDropdown>
    </div>
    <CDataTable
      :items="items"
      :fields="fields"
      hover
      striped
      outlined
      :sorter="sorter"
      @update:sorter-value="handleSortedColumn"
      @row-clicked="handleRowClick"
    >
      <template
        v-for="(field, fieldIndex) in fields"
        v-slot:[field.key]="{ index }"
      >
        <td
          v-if="field.key === 'status'"
          :key="`field-${fieldIndex}`"
          :style="field._style"
        >
          <template v-if="enableSwitchStatus">
            <CSwitch
              id="banner-status"
              type="checkbox"
              class="mx-1"
              color="success"
              shape="pill"
              :checked="!!items[index].status"
              @update:checked="handleStatusUpdate"
            />
          </template>
          <CBadge v-else :color="getBadge(get(items[index], field.key))">
            {{ get(items[index], field.key) }}
          </CBadge>
        </td>
        <td
          v-else-if="field.key === 'actions'"
          :key="`field-${fieldIndex}`"
          class="table-actions"
          :style="field._style"
        >
          <div class="d-flex">
            <!-- @slot Slot to append row actions -->
            <slot
              :id="items[index].id"
              name="actions"
              :item="items[index]"
            ></slot>
          </div>
        </td>
        <td v-else :key="`field-${fieldIndex}`" :style="field._style">
          <router-link :to="baseRoute + items[index].id">
            <div class="d-flex align-items-center clickable-column">
              <img
                v-if="field.thumb"
                :src="items[index].thumb"
                width="40"
                height="40"
                class="mr-3 thumb"
              />
              <i
                v-if="field.featured"
                class="fa-star mr-2 text-warning"
                :class="get(items[index], 'featured', '') ? 'fas' : 'far'"
              ></i>
              {{ get(items[index], field.key, '') }}
            </div>
          </router-link>
        </td>
      </template>
      <template #no-items-view>
        <div class="text-center">
          Nenhum registro foi encontrado! Verifique os dados da sua pesquisa ou
          crie um novo registro.
        </div>
      </template>
    </CDataTable>
  </div>
</template>

<script>
import { get } from 'lodash'

/**
 * @see https://coreui.io/vue/docs/components/table.html#cdatatable-api
 */
export default {
  props: {
    /**
     * Array of objects, where each object represents one row in table.
     */
    items: {
      type: Array,
      default: () => {
        return []
      }
    },

    /**
     * Prop for table columns configuration. If prop is not defined, table will display columns based on the first item keys, omitting keys that begins with underscore (e.g. '_classes')
     */
    fields: {
      type: Array,
      default: () => {
        return []
      }
    },

    /**
     * Enables table sorting by column value. Sorting will be performed corectly only if values in column are of one type: string (case insensitive) or number.
     */
    sorter: {
      type: [Boolean, Object],
      default: () => {
        return {
          external: true,
          resetable: true
        }
      }
    },

    /**
     * Enable table export to json, csv and xls
     */
    enableExport: {
      type: Boolean,
      default: true
    },

    /**
     * Enable which type of component status will be displayed
     * if true dispaly as switch component else as badge component
     */
    enableSwitchStatus: {
      type: Boolean,
      default: true
    },

    /**
     * Sets base route to redirect on certain pages that needs child routing.
     */
    baseRoute: {
      type: String,
      default: ''
    },

    /**
     * Set prop to validate if user can update certain row.
     */
    checked: {
      type: Boolean
    }
  },

  data() {
    return {
      labelIcon: {
        labelOn: '\u2713',
        labelOff: '\u2715'
      }
    }
  },

  methods: {
    /**
     * Get method from lodash
     * @see https://lodash.com/docs/#get
     * @public
     */
    get,

    /**
     * Get the badge by status
     * @param {String} status User status
     * @public
     */
    getBadge(status) {
      let color = 'primary'

      if (status === 'Ativo' || status === 'Aprovado') {
        color = 'success'
      } else if (status === 'Inativo' || status === 'Reprovado') {
        color = 'danger'
      }

      return color
    },

    /**
     * Return the column clicked and sorted value.
     * @param {Event} event Datatable event
     * @public
     */
    handleSortedColumn(event) {
      this.$emit('sorterValue', event)
    },

    handleRowClick(event) {
      this.$emit('row-clicked', event)
    },

    handleStatusUpdate(event) {
      this.$emit('update:checked', event)
    },

    /**
     * Export to json, csv, xls files from JSON
     * @public
     */
    exportTable(type) {
      this.$emit('export', type)
    }
  }
}
</script>

<style lang="scss">
a:hover {
  color: #3c4b64;
}

.datatable {
  .c-switch {
    transform: scale(0.7) translate(-10px, 5px);
    margin-left: 0px !important;
  }

  .table-actions {
    .table-action {
      padding-right: 10px;

      &:last-child {
        padding-right: 0px;
      }

      a {
        color: inherit;
      }
    }
  }

  td {
    border: none !important;
  }

  .dropdown {
    button {
      height: 45px;
      padding: 0px !important;
    }
  }

  .thumb {
    object-fit: contain;
  }

  .clickable-column {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
