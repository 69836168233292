import axios from './axios.service'
const FileSaver = require('file-saver')

const method = 'newsletters'

class NewsletterService {
  async index(queryData) {
    try {
      const response = await axios.get(`/${method}`, {
        params: { ...queryData }
      })
      return response.data
    } catch (error) {
      return error
    }
  }

  async show(id) {
    try {
      const response = await axios({
        method: 'get',
        url: `/${method}/${id}`
      })
      return response.data
    } catch (error) {
      return error
    }
  }

  async updateStatus(rowClicked, status) {
    try {
      const response = await axios({
        method: 'put',
        url: `/${method}/status/${rowClicked.id}`,
        data: {
          status
        }
      })
      return response
    } catch (error) {
      return error
    }
  }

  async deleteItem(rowClicked) {
    try {
      const response = await axios.delete(`/${method}/${rowClicked.id}`)
      return response
    } catch (error) {
      return error.response
    }
  }

  async forceDelete(id) {
    try {
      const response = await axios.delete(`/${method}/${id}/forcedelete`)
      return response
    } catch (error) {
      return error.response
    }
  }

  async store(data) {
    try {
      const response = await axios({
        method: 'post',
        url: `/${method}`,
        data
      })
      return response
    } catch (error) {
      return error.response
    }
  }

  async update(id, data) {
    try {
      const response = await axios({
        method: 'put',
        url: `/${method}/${id}`,
        data
      })
      return response
    } catch (error) {
      return error.response
    }
  }

  async exportXLS() {
    try {
      const file = await axios({
        method: 'get',
        url: `/export/${method}`,
        responseType: 'blob'
      })
      const xls = file.request.response
      FileSaver.saveAs(xls, 'newsletters.xlsx')
    } catch (error) {
      return error.response
    }
  }
}

export default new NewsletterService()
